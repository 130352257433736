import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import SEO from '../components/seo';
import Layout from '../components/layouts/Layout';
import BackButton from '../components/widgets/back';
import ContentLoading from '../components/widgets/content_loading';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { removeCountryCode } from '../utils/formatPhone';

import '../components/help/style.scss';
import { getHTML, getText } from '../utils/cms_copy_provider';
import { isBrowser } from '../utils';

const initialFeedbackFormValues = {
    firstname: '',
    lastname: '',
    email: '',
    issueDate: moment().format('MM/DD/YYYY'),
    zipcode: '',
    phone: '',
    promotion: '',
    comments: '',
    csrf_token: '',
    form_submitted: '1',
    issue_zone: '',
    issue_category: '',
    issue_categories: [],
};

const FeedbackSchema = Yup.object().shape({
    firstname: Yup.string().required('Please enter your first name.'),
    lastname: Yup.string().required('Please enter your last name.'),
    email: Yup.string()
        .email('A valid email is required.')
        .required('A valid email is required.'),
    issueDate: Yup.string()
        .required('Please provide a valid date.')
        .matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, 'Please input your date as YYYY/MM/DD.'),
    zipcode: Yup.string()
        .required('Please enter your zip code.')
        .matches(/^[0-9]{5}$/, 'Please enter a valid ZIP code.'),
    phone: Yup.string()
        .required('Please enter your phone number.')
        .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'Please enter a valid phone number.'),
    promotion: Yup.string().required('Please select or enter a Second-Chance Drawing.'),
    comments: Yup.string().required('Please provide a description.'),
    issue_zone: Yup.string().required('Please select one.'),
    issue_category: Yup.string().required('Please select one.'),
});

export class Help extends React.Component {
    constructor(props) {
        super(props);
        this.submitFeedback = this.submitFeedback.bind(this);
    }

    componentDidMount() {
        this.props.actions.feedbackActions.reset();
        this.props.actions.promotionActions.getPromotions();
        this.props.actions.feedbackCategoriesActions.getFeedbackCategories();
    }

    async submitFeedback(values, { setSubmitting, setErrors, setStatus, resetForm }) {
        this.props.actions.feedbackActions.reset();
        await this.props.actions.feedbackActions.postFeedback(values);

        if (this.props.feedback.success) {
            resetForm();
            setStatus(true);
        } else {
            setStatus(false);
        }

        setSubmitting(false);
        isBrowser() ? window.scrollTo(0, 0) : null;
    }

    renderSuccess(success) {
        if (success) {
            return (
                <div className='alert alert-success text-center' role='alert'>
                    <p>
                        <strong>{getText('help_msg_success_title')}</strong>
                    </p>
                    <p>{getHTML('help_msg_success_body')}</p>
                </div>
            );
        }
    }

    renderError(isError, error) {
        var errors = Object.keys(error).map(er => {
            return (
                <>
                    {er}: {error[er]}
                    <br />
                </>
            );
        });
        if (isError) {
            return (
                <div className='alert alert-danger text-center' role='alert'>
                    {errors.map(err => {
                        return <div key={err}>{err}</div>;
                    })}
                </div>
            );
        }
    }

    promotionDropdown(promotions, values, handleChange) {
        if (promotions.length) {
            return (
                <select name='promotion' value={values.promotion} onChange={handleChange} className='form-control'>
                    <option key='none' value=''>
                        {getText('help_input_promotion')}
                    </option>
                    {[...promotions].map(({ id, name }) => {
                        return (
                            <option key={name} value={name}>
                                {name}
                            </option>
                        );
                    })}
                </select>
            );
        } else {
            return (
                <input
                    type='text'
                    id='promotion'
                    name='promotion'
                    value={values.promotion}
                    onChange={handleChange}
                    size='60'
                    maxLength='60'
                    placeholder={getText('help_input_promotion')}
                    className='form-control'
                />
            );
        }
    }

    phoneField = ({ field, form: { touched, errors }, ...props }) => (
        <>
            <Cleave
                placeholder={getText('help_input_phone')}
                {...field}
                {...props}
                options={{
                    numericOnly: true,
                    blocks: [0, 3, 3, 4],
                    delimiters: ['(', ') ', '-'],
                }}
            />
        </>
    );

    issueZoneSelect = (values, errors, touched, isSubmitting, categories = {}, setFieldValue) => {
        const categoryKeys = Object.keys(categories);
        return (
            <>
                <Field
                    disabled={isSubmitting}
                    as='select'
                    className={'form-control text-and-select' + (errors.issue_zone && touched.issue_zone ? ' is-invalid' : '')}
                    name='issue_zone'
                    id='issue_zone'
                    value={values.issue_zone}
                    onChange={async e => {
                        const { value } = e.target;
                        setFieldValue('issue_zone', value);
                        setFieldValue('issue_category', '');
                        setFieldValue('issue_categories', categories[value]);
                    }}>
                    <option key='none' value=''>
                        Choose One*
                    </option>
                    {categoryKeys.map((zoneName, i) => (
                        <option key={`zone-${zoneName}`} value={zoneName}>
                            {zoneName}
                        </option>
                    ))}
                </Field>
                <ErrorMessage name='issue_zone' component='div' className='invalid-input text-danger' />
            </>
        );
    };

    render() {
        return (
            <Layout user={this.props.user} notification={this.props.notification} config={this.props.config} actions={this.props.actions}>
                <SEO title='Feedback' />
                <div id='nav-target' className='help primary-page-content'>
                    <div className='row'>
                        <div className='col'>
                            <BackButton />
                            <div className='description'>
                                <h2>{getText('help_title')}</h2>
                                <p>{getHTML('help_description')}</p>
                            </div>
                        </div>
                    </div>

                    <ContentLoading hasIcon={true} loadedAction='getPlayerData'>
                        <div className='row'>
                            <div className='col-12'>
                                {this.renderError(this.props.feedback.isError, this.props.feedback.error)}
                                {this.renderSuccess(this.props.feedback.success)}

                                <Formik
                                    initialValues={{
                                        ...initialFeedbackFormValues,
                                        firstname: this.props.user.player.first_name || initialFeedbackFormValues.firstname,
                                        lastname: this.props.user.player.last_name || initialFeedbackFormValues.lastname,
                                        email: this.props.user.player.email || initialFeedbackFormValues.email,
                                        zipcode: this.props.user.player.zip || initialFeedbackFormValues.zipcode,
                                        phone:
                                            removeCountryCode(this.props.user.player.phone1) ||
                                            removeCountryCode(this.props.user.player.phone2) ||
                                            removeCountryCode(initialFeedbackFormValues.phone),
                                    }}
                                    validationSchema={FeedbackSchema}
                                    onSubmit={this.submitFeedback}>
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        /* and other goodies */
                                    }) => (
                                        <Form
                                            onSubmit={handleSubmit}
                                            name='feedback_form'
                                            id='feedback_form'
                                            disabled={isSubmitting}
                                            method='post'
                                            encType='application/x-www-form-urlencoded'>
                                            <div className='row'>
                                                <div className='form-group col-md-6'>
                                                    <Field
                                                        type='text'
                                                        name='firstname'
                                                        placeholder={getText('help_input_firstname')}
                                                        className={errors.firstname && touched.firstname ? `form-control is-invalid` : `form-control`}
                                                        size='120'
                                                        maxLength='120'
                                                    />
                                                    <ErrorMessage
                                                        name='firstname'
                                                        component='div'
                                                        className='error-message invalid-input text-danger'
                                                        data-test='error-message'
                                                    />
                                                </div>
                                                <div className='form-group col-md-6'>
                                                    <Field
                                                        type='text'
                                                        name='lastname'
                                                        placeholder={getText('help_input_lastname')}
                                                        className={errors.lastname && touched.lastname ? `form-control is-invalid` : `form-control`}
                                                        size='120'
                                                        maxLength='120'
                                                    />
                                                    <ErrorMessage
                                                        name='lastname'
                                                        component='div'
                                                        className='error-message invalid-input text-danger'
                                                        data-test='error-message'
                                                    />
                                                </div>
                                                <div className='form-group col-md-6'>
                                                    <Field
                                                        type='text'
                                                        name='email'
                                                        placeholder={getText('help_input_email')}
                                                        className={errors.email && touched.email ? `form-control is-invalid` : `form-control`}
                                                        size='60'
                                                        maxLength='60'
                                                    />
                                                    <ErrorMessage
                                                        name='email'
                                                        component='div'
                                                        className='error-message invalid-input text-danger'
                                                        data-test='error-message'
                                                    />
                                                </div>
                                                <div className='form-group col-md-6'>
                                                    <Cleave
                                                        placeholder={getText('help_input_issuedate')}
                                                        id='issueDate'
                                                        name='issueDate'
                                                        className={errors.issueDate && touched.issueDate ? `form-control is-invalid` : `form-control`}
                                                        options={{
                                                            date: true,
                                                            delimiter: '/',
                                                            datePattern: ['m', 'd', 'Y'],
                                                        }}
                                                        onChange={handleChange}
                                                        value={values.issueDate}
                                                    />
                                                    <ErrorMessage
                                                        name='issueDate'
                                                        component='div'
                                                        className='error-message invalid-input text-danger'
                                                        data-test='error-message'
                                                    />
                                                </div>
                                                <div className='form-group col-md-6'>
                                                    <Field
                                                        type='text'
                                                        name='zipcode'
                                                        placeholder={getText('help_input_zipcode')}
                                                        className={errors.zipcode && touched.zipcode ? `form-control is-invalid` : `form-control`}
                                                        size='5'
                                                        maxLength='5'
                                                    />
                                                    <ErrorMessage
                                                        name='zipcode'
                                                        component='div'
                                                        className='error-message invalid-input text-danger'
                                                        data-test='error-message'
                                                    />
                                                </div>
                                                <div className='form-group col-md-6'>
                                                    <Field
                                                        component={this.phoneField}
                                                        disabled={isSubmitting}
                                                        name='phone'
                                                        id='phone'
                                                        className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}
                                                        value={values.phone}
                                                    />
                                                    <ErrorMessage name='phone' component='div' className='invalid-input text-danger' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='form-group col-md-6 offset-md-3'>
                                                    {this.promotionDropdown(this.props.promotion.promotions, values, handleChange)}
                                                    <ErrorMessage
                                                        name='promotion'
                                                        component='div'
                                                        className='error-message invalid-input text-danger'
                                                        data-test='error-message'
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-row'>
                                                <div className='col form-group'>
                                                    {this.issueZoneSelect(
                                                        values,
                                                        errors,
                                                        touched,
                                                        isSubmitting,
                                                        this.props.feedbackCategories.feedbackCategories.body,
                                                        setFieldValue
                                                    )}
                                                </div>
                                                <div className='col form-group'>
                                                    <Field
                                                        disabled={isSubmitting}
                                                        as='select'
                                                        className={
                                                            'form-control text-and-select' +
                                                            (errors.issue_category && touched.issue_category ? ' is-invalid' : '')
                                                        }
                                                        name='issue_category'
                                                        id='issue_category'
                                                        value={values.issue_category}>
                                                        <option key='none' value=''>
                                                            Choose One*
                                                        </option>
                                                        {values.issue_categories &&
                                                            values.issue_categories.map(categoryName => (
                                                                <option key={`category-${categoryName}`} value={categoryName}>
                                                                    {categoryName}
                                                                </option>
                                                            ))}
                                                    </Field>
                                                    <ErrorMessage name='issue_category' component='div' className='invalid-input text-danger' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='form-group col'>
                                                    <textarea
                                                        id='comments'
                                                        name='comments'
                                                        value={values.comments}
                                                        onChange={handleChange}
                                                        rows='10'
                                                        cols='50'
                                                        wrap='soft'
                                                        maxLength='1200'
                                                        placeholder={getText('help_input_comments')}
                                                        className={errors.comments && touched.comments ? `form-control is-invalid` : `form-control`}
                                                    />
                                                    <ErrorMessage
                                                        name='comments'
                                                        component='div'
                                                        className='error-message invalid-input text-danger'
                                                        data-test='error-message'
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='submit-div col mb-4'>
                                                    <button id='entry-submit' name='submit' type='submit' className='theme-btn' disabled={isSubmitting}>
                                                        {getText('help_btn_submit')}
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </ContentLoading>
                </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Help);
